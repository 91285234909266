@import '../../../styles/customMediaQueries.css';

.container {
  padding: 24px;
  background-color: var(--matterColorLight);
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
}

.title {
  font-size: 28px;
  font-weight: 700;
  color: var(--marketplaceColor);
  margin-bottom: 32px;
  text-align: center;
  position: relative;
}

.title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background: var(--marketplaceColor);
  margin: 16px auto 0;
  border-radius: 2px;
}

.faqItem {
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faqItem:last-child {
  margin-bottom: 24px;
}

.faqItem.open {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.questionButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.questionButton:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.question {
  font-size: 18px;
  font-weight: 600;
  color: var(--matterColor);
  margin: 0;
  padding-right: 40px;
}

.arrow {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  background-color: var(--marketplaceColor);
  transition: transform 0.3s ease;
}

.arrow::before {
  top: 9px;
  left: 0;
  width: 20px;
  height: 2px;
}

.arrow::after {
  top: 0;
  left: 9px;
  width: 2px;
  height: 20px;
}

.open .arrow::after {
  transform: rotate(90deg);
}

.answerContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.open .answerContainer {
  max-height: 1000px;
  padding: 0 20px 20px;
  background-color: white;
}

.answer {
  font-size: 16px;
  line-height: 1.6;
  color: var(--matterColorDark);
  margin-bottom: 16px;
}

.answer:last-child {
  margin-bottom: 0;
}

.benefitsList {
  margin: 0 0 0 24px;
  padding: 0;
  list-style-type: disc;
}

.benefitsList li {
  margin-bottom: 8px;
  line-height: 1.6;
  padding-left: 8px;
  position: relative;
}

.benefitsList li::marker {
  color: var(--marketplaceColor);
  font-size: 1.2em;
}

.benefitsList li:last-child {
  margin-bottom: 0;
}

.viewMoreContainer {
  text-align: center;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.viewMoreLink {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--marketplaceColor);
  color: white;
  font-weight: 600;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  min-width: 180px;
}

.viewMoreLink:hover {
  background-color: var(--marketplaceColorDark);
  transform: translateY(-2px);
}

@media (--viewportMedium) {
  .container {
    padding: 40px;
  }
  
  .title {
    font-size: 32px;
  }
  
  .question {
    font-size: 20px;
  }
  
  .answer {
    font-size: 17px;
  }
  
  .viewMoreContainer {
    flex-direction: row;
    justify-content: center;
    gap: 24px;
  }
}

@media (--viewportLarge) {
  .container {
    padding: 48px;
  }
  
  .title {
    font-size: 36px;
    margin-bottom: 48px;
  }
  
  .faqItem {
    margin-bottom: 20px;
  }
  
  .question {
    font-size: 22px;
  }
} 