@import '../../../styles/customMediaQueries.css';

.root {
  padding: 24px;
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  @media (--viewportMedium) {
    padding: 36px;
    margin-top: 64px;
    margin-bottom: 72px;
  }
  
  @media (--viewportLarge) {
    padding: 48px;
    margin-top: 72px;
    max-width: 1128px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 96px;
  }
}

/* Title styles kept for reference but not used anymore */
.title {
  display: none;
}

/* Base section styles */
.sectionImageLeft,
.sectionImageRight {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  width: 100%;
  max-width: 1128px;
  
  @media (--viewportMedium) {
    align-items: center;
    margin-bottom: 64px;
  }
}

/* Section with image on left for desktop */
.sectionImageLeft {
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

/* Section with image on right for desktop */
.sectionImageRight {
  @media (--viewportMedium) {
    flex-direction: row-reverse;
  }
}

/* Image column - always first on mobile */
.imageColumn {
  flex: 1;
  margin-bottom: 24px;
  order: 1;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
  
  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0 24px;
    order: unset; /* Reset order on medium screens to allow for alternating layout */
    min-height: 400px;
    max-width: 50%;
  }
}

/* Image link styling */
.imageLink {
  display: block;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
  
  &:hover {
    transform: scale(1.02);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--marketplaceColor);
    border-radius: 8px;
  }
}

/* Image container for positioning the overlay */
.imageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
}

/* Text column - always second on mobile */
.textColumn {
  flex: 1;
  order: 2;
  padding: 0 16px;
  width: 100%;
  
  @media (--viewportMedium) {
    padding: 0 24px;
    order: unset; /* Reset order on medium screens to allow for alternating layout */
    max-width: 50%;
  }
}

/* Image styling */
.image {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

/* Tester information overlay - now always visible */
.testerInfoOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 70%, transparent 100%);
  color: white;
  padding: 20px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* Remove transform and transition since it's always visible */
}

/* Tester name styling */
.testerName {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Tester age and location styling */
.testerAgeLocation {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Tester role styling */
.testerRole {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Tester description styling */
.testerDescription {
  font-size: 14px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Section title */
.sectionTitle {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 16px;
  color: var(--marketplaceColor);
  
  @media (--viewportMedium) {
    font-size: 26px;
    margin-bottom: 20px;
  }
  
  @media (--viewportLarge) {
    font-size: 30px;
    margin-bottom: 24px;
  }
}

/* Section text */
.sectionText {
  font-size: 16px;
  line-height: 1.6;
  margin: 0 0 16px;
  color: var(--matterColor);
  
  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 1.7;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
} 